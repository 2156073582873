  
import L_HTML5 from "../../assets/img/skills/html-5.svg";
import L_CSS3 from "../../assets/img/skills/css3.svg";
import L_SASS from "../../assets/img/skills/sass-1.svg";
import L_BOOTSTRAP from "../../assets/img/skills/bootstrap-4.svg";
import L_REACT from "../../assets/img/skills/react.svg";
import L_REDUX from "../../assets/img/skills/redux.svg";
import L_REACT_ROUTER from "../../assets/img/skills/react-router.svg";
import L_MATERIALUI from "../../assets/img/skills/material-ui-1.svg";
import L_REACT_BOOTSTRAP from "../../assets/img/skills/react-bootstrap.svg";
import L_STYLED_COMPONENTS from "../../assets/img/skills/styled-components.svg";
import L_NODE_JS from "../../assets/img/skills/nodejs.svg";
import L_EXPRESS from "../../assets/img/skills/express.svg";
import L_DJANGO from "../../assets/img/skills/django.svg";
import L_FLASK from "../../assets/img/skills/flask.svg";
import L_MONGODB from "../../assets/img/skills/mongodb.svg";
import L_POSTGRESQL from "../../assets/img/skills/postgresql.svg";
import L_MSSQL from "../../assets/img/skills/mssql.svg";
import L_GIT from "../../assets/img/skills/git-icon.svg";
import L_HEROKU from "../../assets/img/skills/heroku.svg";
import L_DIGITAL_OCEAN from "../../assets/img/skills/digital-ocean.svg";
import L_GITHUB_PAGES from "../../assets/img/skills/github.svg";
import L_JAVASCRIPT from "../../assets/img/skills/javascript.svg";
import L_TYPESCRIPT from "../../assets/img/skills/typescript.svg";
import L_GOLANG from "../../assets/img/skills/go-6.svg";
import L_PYTHON from "../../assets/img/skills/python.svg";
import NEXT from '../../assets/img/skills/Next.png';
import POSTMAN from '../../assets/img/skills/POSTMAN.png';
import FIREBASE from '../../assets/img/skills/Firebase.png';
import AWS from '../../assets/img/skills/AWS.png';
import ANSIBLE from '../../assets/img/skills/Ansible.png';
import NETMIKO from '../../assets/img/skills/netmiko.jpg';
import CCNA from '../../assets/img/skills/CCNA.png';
import CCNP from '../../assets/img/skills/CCNP.png';
import VELOCLOUD from '../../assets/img/skills/Velocloud.png';
import MERAKI from '../../assets/img/skills/Meraki.png';
import PRISMA from '../../assets/img/skills/Prisma.png';
import IOS from '../../assets/img/skills/cisco ios.png'

export const skills = {
  frontend: [
    {
      link: "https://github.com/ktbyers/netmiko",
      imgAltText: "Netmiko",
      imgSrc: NETMIKO,
      skillName: "Paramiko",
    },
    
  ],

  certifications: [
    {
      link: "https://www.cisco.com/c/en/us/training-events/training-certifications/certifications/associate/ccna.html",
      imgAltText: "CCNA",
      imgSrc: CCNA,
      skillName: "CCNA",
    },
    {
      link: 'https://www.cisco.com/site/us/en/learn/training-certifications/certifications/enterprise/ccnp-enterprise/index.html',
      imgAltText: 'CCNP',
      imgSrc: CCNP,
      skillName: 'CCNP ENCOR 350-401 (in progress)'
    }
  ],

  backend: [
    {
      link: "https://en.wikipedia.org/wiki/HTML5",
      imgAltText: "HTML 5",
      imgSrc: L_HTML5,
      skillName: "HTML5",
    },
    {
      link: "https://medium.com/beginners-guide-to-mobile-web-development/whats-new-in-css-3-dcd7fa6122e1",
      imgAltText: "CSS 3",
      imgSrc: L_CSS3,
      skillName: "CSS3",
    },
    {
      link: "https://www.javascript.com/",
      imgAltText: "JavaScript",
      imgSrc: L_JAVASCRIPT,
      skillName: "JavaScript",
    },
    {
      link: "https://www.postman.com/",
      imgAltText: "Postman",
      imgSrc: POSTMAN,
      skillName: "Postman",
    },
    {
      link: "https://nodejs.org/en/",
      imgAltText: "Node.js",
      imgSrc: L_NODE_JS,
      skillName: "Node.js",
    },
    {
      link: "https://expressjs.com/",
      imgAltText: "Express",
      imgSrc: L_EXPRESS,
      skillName: "Express",
    },
    // {
    //   link: "https://www.djangoproject.com/",
    //   imgAltText: "Django",
    //   imgSrc: L_DJANGO,
    //   skillName: "Django",
    // },
    // {
    //   link: "https://flask.palletsprojects.com/",
    //   imgAltText: "Flask",
    //   imgSrc: L_FLASK,
    //   skillName: "Flask",
    // },
  ],
  hostingPlatforms: [
    {
      link: "https://aws.amazon.com/",
      imgAltText: "AWS",
      imgSrc: AWS,
      skillName: "AWS",
    },
    {
      link: "https://www.heroku.com/",
      imgAltText: "Heroku",
      imgSrc: L_HEROKU,
      skillName: "Heroku",
    },
    // {
    //   link: "https://www.digitalocean.com/",
    //   imgAltText: "Digital Ocean",
    //   imgSrc: L_DIGITAL_OCEAN,
    //   skillName: "Digital Ocean",
    // },
    // {
    //   link: "https://pages.github.com/",
    //   imgAltText: "GitHub Pages",
    //   imgSrc: L_GITHUB_PAGES,
    //   skillName: "GitHub Pages",
    // },
  ],
  programmingLanguages: [
    {
      link: "https://www.javascript.com/",
      imgAltText: "JavaScript",
      imgSrc: L_JAVASCRIPT,
      skillName: "JavaScript",
    },
    // {
    //   link: "https://www.typescriptlang.org/",
    //   imgAltText: "TypeScript",
    //   imgSrc: L_TYPESCRIPT,
    //   skillName: "TypeScript",
    // },
    {
      link: "https://www.python.org/",
      imgAltText: "Python",
      imgSrc: L_PYTHON,
      skillName: "Python",
    },
    // {
    //   link: "https://golang.org/",
    //   imgAltText: "Golang",
    //   imgSrc: L_GOLANG,
    //   skillName: "Golang",
    // },
  ],
  databases: [
    {
      link: "https://www.cisco.com/",
      imgAltText: "Cisco IOS",
      imgSrc: IOS,
      skillName: "Cisco IOS",
    },
    {
      link: "https://www.paloaltonetworks.com/sase/sd-wan",
      imgAltText: "Palo Alto SD-WAN",
      imgSrc: PRISMA,
      skillName: "Palo Alto SD-WAN",
    },
    {
      link: "https://sase.vmware.com/",
      imgAltText: "Velocloud SD-WAN",
      imgSrc: VELOCLOUD,
      skillName: "Velocloud SD-WAN",
    },
    {
      link: "https://meraki.cisco.com/",
      imgAltText: "Cisco Meraki",
      imgSrc: MERAKI,
      skillName: "Cisco Meraki",
    },
  ],
  versionControl: [
    {
      link: "https://git-scm.com/",
      imgAltText: "GIT",
      imgSrc: L_GIT,
      skillName: "GIT",
    },
  ],
};