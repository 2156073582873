// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
  background-color: transparent;
  font-size: 1.1em;
}

.profile {
  max-width: 18em;
}

.my-details {
  background-color: rgba(255, 255, 255, 0.747);
}`, "",{"version":3,"sources":["webpack://./src/pages/about/about.style.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".about {\n  background-color: transparent;\n  font-size: 1.1em;\n}\n\n.profile {\n  max-width: 18em;\n}\n\n.my-details {\n  background-color: rgba(255, 255, 255, 0.747);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
