// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-img {
    height: 100vh;
    object-fit: cover;
  }`, "",{"version":3,"sources":["webpack://./src/components/my-carousel/my-carousel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;EACnB","sourcesContent":[".custom-img {\n    height: 100vh;\n    object-fit: cover;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
