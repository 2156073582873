// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/background/background.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-jumbotron {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  #youtube-btn {
    border-color: #f83f37;
    color: #f83f37;
  }
  #youtube-btn:hover {
    background-color: #f83f37;
    color: #ffffff;
  }`, "",{"version":3,"sources":["webpack://./src/pages/contact-form/contact-form.styles.css"],"names":[],"mappings":"AAAA;IACI,yDAAoE;IACpE,sBAAsB;IACtB,4BAA4B;EAC9B;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB;EACA;IACE,yBAAyB;IACzB,cAAc;EAChB","sourcesContent":[".contact-jumbotron {\n    background-image: url(\"../../assets/img/background/background.webp\");\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n  \n  #youtube-btn {\n    border-color: #f83f37;\n    color: #f83f37;\n  }\n  #youtube-btn:hover {\n    background-color: #f83f37;\n    color: #ffffff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
