import React from "react";
import { Timeline, Events, UrlButton, ImageEvent } from "@merc/react-timeline";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import background from "../../assets/img/projects/background-generator.PNG";
import intro from "../../assets/img/projects/bootstrap-intro.PNG";
import brain from "../../assets/img/projects/brain.webp";
import contact from "../../assets/img/projects/contact-keeper.PNG";
import github from "../../assets/img/projects/github-finder.PNG";
import robofriends from "../../assets/img/projects/Robofriends.webp";
import ecommerce from "../../assets/img/projects/ecommerce.PNG";
import linkShare from "../../assets/img/projects/LINK_SHARING.PNG";

import L_REACT from "../../assets/img/skills/react.svg";
import L_NODE_JS from "../../assets/img/skills/nodejs.svg";
import L_EXPRESS from "../../assets/img/skills/express.svg";
import L_POSTGRESQL from "../../assets/img/skills/postgresql.svg";
import L_MONGODB from "../../assets/img/skills/mongodb.svg";
import Image from "react-bootstrap/Image";
import L_REDUX from "../../assets/img/skills/redux.svg";
import L_HTML5 from "../../assets/img/skills/html-5.svg";
import L_CSS3 from "../../assets/img/skills/css3.svg";
import L_BOOTSTRAP4 from "../../assets/img/skills/bootstrap-4.svg";
import L_DJANGO from "../../assets/img/skills/django.svg";
import Gaming from "../../assets/img/skills/Gaming.jpg";
import L_GIT from "../../assets/img/skills/github-api.svg";
import L_MATERIALUI from "../../assets/img/skills/material-ui-1.svg";
import L_STYLED_COMPONENTS from "../../assets/img/skills/styled-components.svg";
import L_REACT_ROUTER from "../../assets/img/skills/react-router.svg";
import AWS from "../../assets/img/skills/AWS.png";
import POSTMAN from "../../assets/img/skills/POSTMAN.png";
import FIREBASE from "../../assets/img/skills/Firebase.png";
import NEXT from "../../assets/img/skills/Next.png";
import NOBLESHOME from "../../assets/img/skills/NoblesHome.PNG";
import network from "../../assets/img/skills/Network.jpg";
import samba from "../../assets/img/skills/Samba.jpg";
import automation from "../../assets/img/skills/Enterprise.jpg";
import firewall from '../../assets/img/skills/fw resize.png';
import MPLS from '../../assets/img/skills/MPLS.PNG'

const Project = () => {
  return (
    <div id="projects">
      <h1 className="pt-3 text-center font-details-b pb-3">PROJECTS</h1>
      <Timeline>
        <Events>
          <ImageEvent
            date="In progress"
            className="text-center"
            text="Enterprise Network Lab w/ Cisco ASA & High Availability (GNS3)"
            src={firewall}
            alt="network"
          >
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      className="p-2 text-center accordian-main"
                    >
                      PROJECT DETAILS
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description: </strong>
                        configured a lab featuring active/passive failover, OSPF
                        load balancing, HSRP load balancing, and a collapsed
                        core model. I conducted tests on OSPF reconvergence,
                        HSRP failover, and ASA failover, all while maintaining a
                        continuous ping from a Docker container on the corporate
                        VLAN. Once the ENCOR exam is completed, this will serve
                        as the place where I run network automation scripts for
                        practice.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </ImageEvent>
          <ImageEvent
            date="04/2024"
            className="text-center"
            text="MPLS Network Lab (GNS3)"
            src={MPLS}
            alt="network"
          >
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      className="p-2 text-center accordian-main"
                    >
                      PROJECT DETAILS
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description: </strong>
                        Created a lab project involving BGP, MPLS, Cisco ASAs,
                        and client/server devices across the MPLS network.
                        Implemented DHCP server functionality from the hub to
                        one of the branch locations.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </ImageEvent>
          <ImageEvent
            date="11/15/2021"
            className="text-center"
            text="Enterprise Network Lab (GNS3)"
            src={automation}
            alt="network"
          >
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      className="p-2 text-center accordian-main"
                    >
                      PROJECT DETAILS
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description: </strong>
                        Created a collapsed-core network using multilayer
                        switches, layer 2 switches, docker containers, VPCs and
                        an edge router.
                        <hr />
                        <strong>Features:</strong>
                        <ul className="list-styles pt-1">
                          <li>Device security (local user and passwords)</li>
                          <li>
                            VTPv3 primary/secondary server and clients (core and
                            access switches)
                          </li>
                          <li>
                            Muliple VLANS (1, 10, 20, 30, 100 for servers)
                          </li>
                          <li>802.1Q trunking</li>
                          <li>Access ports to VPCs/Servers</li>
                          <li>
                            Spanning Tree (RSTP, also implemented load balancing
                            between core switches and VLANs)
                          </li>
                          <li>LACP on core devices for redundancy</li>
                          <li>
                            VRRP default gateway load balancing (also load
                            balanced according to spanning tree roots)
                          </li>
                          <li>
                            Configured Edge router for NAT overload, OSPF, ACL
                            and an interface for private network
                          </li>
                          <li>
                            Added google public DNS server (8.8.8.8) on VPCs and
                            Ubuntu server and pinged my portfolio website
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </ImageEvent>
          <ImageEvent
            date="7/16/2021"
            className="text-center"
            text="Housing Payment Gateway (Client website)"
            src={NOBLESHOME}
            alt="Housing Payment Gateway"
          >
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      className="p-2 text-center accordian-main"
                    >
                      PROJECT DETAILS
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description:</strong> Housing website with
                        payment gateway, tenant portal and messages for tenants.
                        <hr />
                        <strong>Features:</strong>
                        <ul className="list-styles pt-1">
                          <li>
                            Built an application that uses a payment gateway
                            (Stripe API) for tenants to pay homeowners.
                          </li>
                          <li>
                            Implemented SMS text messages using the Twilio SMS
                            API.
                          </li>
                          <li>
                            Utilized documentation from AWS and Stripe SDK to
                            implement microservices resulting in faster, more
                            secure service.
                          </li>
                          <li>
                            Managed invoices using controller logic and Stripe,
                            which ensured that customers retirved accurate order
                            data.
                          </li>
                          <li>
                            Utilized Postman to create API endpoints to provide
                            felxibility and increase scalability to the website.
                          </li>
                          <li>
                            Provided hashing and .env salt to protect API keys,
                            user passwords, sessions, and password reset paths
                            to completely secure the website.
                          </li>
                          <li>
                            Updated Client with progress of the website every
                            step of the way to ensure customer sactisfaction.
                          </li>
                        </ul>
                        <hr />
                        <strong>Tech used:</strong>
                        <ul>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_MONGODB}
                                alt="MongoDB"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              MongoDB
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_EXPRESS}
                                alt="Expressjs"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              Express.js
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_NODE_JS}
                                alt="Nodejs"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              Node.js
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={AWS}
                                alt="AWS"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              AWS
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_REACT}
                                alt="REACT"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              React
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={POSTMAN}
                                alt="POSTMAN"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              Postman
                            </span>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
              <div className="d-flex justify-content-between flex-nowrap text-center">
                {/* <UrlButton
                  href="https://fathomless-headland-07094.herokuapp.com/"
                  target="_blank"
                >
                  SEE LIVE
                </UrlButton> */}
                <UrlButton>Private Repo</UrlButton>
              </div>
            </div>
          </ImageEvent>
          <ImageEvent
            date="6/12/2021"
            className="text-center"
            text="Link sharing App"
            src={linkShare}
            alt="Link sharing App"
          >
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      className="p-2 text-center accordian-main"
                    >
                      PROJECT DETAILS
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description:</strong> Scalable web application
                        created with AWS, and Next.js
                        <hr />
                        <strong>Features:</strong>
                        <ul className="list-styles pt-1">
                          <li>
                            Built a full-stack web app to allow users to create
                            an account to share links of certain categories.
                          </li>
                          <li>
                            Implemented SEO (search engine optimization) for
                            server-side rendering and web-crawler optimization.
                          </li>
                          <li>
                            Utilized documentation from AWS SDK to implement
                            microservices resulting in faster, more secure
                            service.
                          </li>
                          <li>
                            Managed invoices using controller logic and PDFkit,
                            which ensured that customers retirved accurate order
                            data
                          </li>
                          <li>
                            Utilized API endpoints to provide felxibility and
                            increase scalability to the website
                          </li>
                          <li>
                            Provided hashing and salt to protect API keys, user
                            passwords, sessions, and password reset paths to
                            completely secure the website
                          </li>
                        </ul>
                        <hr />
                        <strong>Tech used:</strong>
                        <ul>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_MONGODB}
                                alt="MongoDB"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              MongoDB
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_EXPRESS}
                                alt="Expressjs"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              Express.js
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_NODE_JS}
                                alt="Nodejs"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              Node.js
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={AWS}
                                alt="AWS"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              AWS
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={NEXT}
                                alt="NEXT"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              Next.js
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={POSTMAN}
                                alt="POSTMAN"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              Postman
                            </span>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
              <div className="d-flex justify-content-between flex-nowrap text-center">
                {/* <UrlButton
                  href="https://fathomless-headland-07094.herokuapp.com/"
                  target="_blank"
                >
                  SEE LIVE
                </UrlButton> */}
                <UrlButton
                  href="http://ec2-18-118-147-9.us-east-2.compute.amazonaws.com/"
                  target="_blank"
                >
                  LIVE WEBSITE
                </UrlButton>
              </div>
            </div>
          </ImageEvent>
          {/* Node.js E-commerce */}
          <ImageEvent
            date="04/16/2021"
            className="text-center"
            text="Nodejs Ecommerce Project"
            src={ecommerce}
            alt="Nodejs Ecommerce Project"
          >
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      className="p-2 text-center accordian-main"
                    >
                      PROJECT DETAILS
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description:</strong> Shopping website created
                        with node.js, express.js, API, and MongoDB
                        <hr />
                        <strong>Features:</strong>
                        <ul className="list-styles pt-1">
                          <li>
                            Applied an MVC pattern to develop shop user
                            interface and backend interaction resulting in
                            orderly and thorough code
                          </li>
                          <li>
                            Reserved database schema, methods and interaction
                            within a model and controller folder
                          </li>
                          <li>
                            Implemented Stripe.js API to handle payments,
                            increasing payment security
                          </li>
                          <li>
                            Managed invoices using controller logic and PDFkit,
                            which ensured that customers retirved accurate order
                            data
                          </li>
                          <li>
                            Utilized API endpoints to provide felxibility and
                            increase scalability to the website
                          </li>
                          <li>
                            Provided hashing and salt to protect API keys, user
                            passwords, sessions, and password reset paths to
                            completely secure the website
                          </li>
                        </ul>
                        <hr />
                        <strong>Tech used:</strong>
                        <ul>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_MONGODB}
                                alt="MongoDB"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              MongoDB
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_EXPRESS}
                                alt="Expressjs"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              Express.js
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_NODE_JS}
                                alt="Nodejs"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              Node.js
                            </span>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
              <div className="d-flex justify-content-between flex-nowrap text-center">
                {/* <UrlButton
                  href="https://fathomless-headland-07094.herokuapp.com/"
                  target="_blank"
                >
                  SEE LIVE
                </UrlButton> */}
                <UrlButton
                  href="https://github.com/Aaron24000/nodejs-ecommerce"
                  target="_blank"
                >
                  SOURCE CODE
                </UrlButton>
              </div>
            </div>
          </ImageEvent>
          <ImageEvent
            date="7/16/2020"
            className="text-center"
            text="Samba File Server"
            src={samba}
            alt="samba"
          >
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      className="p-2 text-center accordian-main"
                    >
                      PROJECT DETAILS
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description: </strong>
                        Created a file server, and VPN on a raspberry pi with a
                        2TB HDD. Used Debian Linux, Samba (file server), and
                        PiVPN with OpenVPN
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </ImageEvent>
          <ImageEvent
            date="11/16/2019"
            className="text-center"
            text="Sleep Outfitters Network Integration"
            src={network}
            alt="network"
          >
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      className="p-2 text-center accordian-main"
                    >
                      PROJECT DETAILS
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description: </strong>
                        Deployed network equipment for 100+ Sleep Outfitters
                        locations across 5 states for an MSP WAN implementation
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </ImageEvent>
          {/* <ImageEvent
            date="11/16/2020"
            className="text-center"
            text="Bootstrap"
            src={intro}
            alt="bootstrap"
          >
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      className="p-2 text-center accordian-main"
                    >
                      PROJECT DETAILS
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description:</strong> Landing pages using bootstrap4 and mailchimp
                        <hr />
                        <strong>Features:</strong>
                        <ul className="list-styles pt-1">
                          <li>Mailing list</li>
                        </ul>
                        <hr />
                        <strong>Tech used:</strong>
                        <ul>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_BOOTSTRAP4}
                                alt="styled components"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              Bootstrap
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image
                                src={L_HTML5}
                                alt="HTML"
                                rounded
                                className="image-style m-1"
                              ></Image>{" "}
                              HTML5
                            </span>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
              <div className="d-flex justify-content-between flex-nowrap text-center">
                <UrlButton
                  href="https://aaron24000.github.io/Bootstrap-Intro/"
                  target="_blank"
                >
                  SEE LIVE
                </UrlButton>
                <UrlButton
                  href="https://github.com/Aaron24000/Bootstrap-Intro"
                  target="_blank"
                >
                  SOURCE CODE
                </UrlButton>
              </div>
            </div>
          </ImageEvent> */}
          <ImageEvent
            date="09/27/2015"
            className="text-center"
            text="Computer Build"
            src={Gaming}
            alt="Computer"
          >
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      className="p-2 text-center accordian-main"
                    >
                      PROJECT DETAILS
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description:</strong> Built my first-ever
                        computer for gaming and schoolwork
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
              <div className="d-flex justify-content-between flex-nowrap text-center"></div>
            </div>
          </ImageEvent>
        </Events>
      </Timeline>
    </div>
  );
};

export default Project;
