// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    width: 25%;
  }
  
  .nav-theme {
    background-color: #101018f8;
    font-size: 20px;
  }
  
  .animate-navbar {
    box-shadow: 1px 1px 1px #222;
    animation: moveDown 0.5s ease-in-out;
  }
  
  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
    to {
      transform: translateY(0rem);
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/my-navbar/mynavbar.css"],"names":[],"mappings":"AAAA;IACI,UAAU;EACZ;;EAEA;IACE,2BAA2B;IAC3B,eAAe;EACjB;;EAEA;IACE,4BAA4B;IAC5B,oCAAoC;EACtC;;EAEA;IACE;MACE,4BAA4B;IAC9B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":[".logo {\n    width: 25%;\n  }\n  \n  .nav-theme {\n    background-color: #101018f8;\n    font-size: 20px;\n  }\n  \n  .animate-navbar {\n    box-shadow: 1px 1px 1px #222;\n    animation: moveDown 0.5s ease-in-out;\n  }\n  \n  @keyframes moveDown {\n    from {\n      transform: translateY(-5rem);\n    }\n    to {\n      transform: translateY(0rem);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
