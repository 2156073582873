// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  
.footer-style {
    background-color: #101018f8;
    color: #fff;
    font-size: 1.5em;
    border: 2px solid #101018f8;
    border-top-left-radius: 3px;
  }`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.style.css"],"names":[],"mappings":";AACA;IACI,2BAA2B;IAC3B,WAAW;IACX,gBAAgB;IAChB,2BAA2B;IAC3B,2BAA2B;EAC7B","sourcesContent":["  \n.footer-style {\n    background-color: #101018f8;\n    color: #fff;\n    font-size: 1.5em;\n    border: 2px solid #101018f8;\n    border-top-left-radius: 3px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
