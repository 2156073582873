// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-resize {
  width: 10em;
}

.style {
  font-size: 20px;
}

.jumbo-style {
  background-image: linear-gradient(
    -225deg,
    #2cd8d5 0%,
    #6b8dd6 48%,
    #8e37d7 100%
  );
}

.body-title-style {
  font-size: 25px;
  font-weight: 800;
}
`, "",{"version":3,"sources":["webpack://./src/pages/experience/experience.style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;;;;;GAKC;AACH;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".img-resize {\n  width: 10em;\n}\n\n.style {\n  font-size: 20px;\n}\n\n.jumbo-style {\n  background-image: linear-gradient(\n    -225deg,\n    #2cd8d5 0%,\n    #6b8dd6 48%,\n    #8e37d7 100%\n  );\n}\n\n.body-title-style {\n  font-size: 25px;\n  font-weight: 800;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
