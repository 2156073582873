// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.focus {
    transition: transform 0.3s;
  }
  
  .focus:hover {
    transform: scale(1.05);
  }
  
  .card-title {
    font-size: 2em;
    font-weight: 700;
  }
  
  .image-style {
    width: 3em;
  }
  
  .font-details {
    font-size: 3em;
    font-weight: 500;
    color: #231d5af8;
  }
  
  .font-details-b {
    font-size: 3em;
    font-weight: 500;
    color: #ffffff;
  }`, "",{"version":3,"sources":["webpack://./src/pages/skills/skills.style.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;EAC5B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":[".focus {\n    transition: transform 0.3s;\n  }\n  \n  .focus:hover {\n    transform: scale(1.05);\n  }\n  \n  .card-title {\n    font-size: 2em;\n    font-weight: 700;\n  }\n  \n  .image-style {\n    width: 3em;\n  }\n  \n  .font-details {\n    font-size: 3em;\n    font-weight: 500;\n    color: #231d5af8;\n  }\n  \n  .font-details-b {\n    font-size: 3em;\n    font-weight: 500;\n    color: #ffffff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
